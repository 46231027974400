import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import PatekCategoryBtns from '../../components/preowned/PatekCategoryBtns'

// markup
const PatekAnnualCalendar = () => {
  const data = useStaticQuery(
    graphql`
      query queryPatekCalatrava {
        products: allStrapiProduct(
          filter: {
            brand: { eq: "Patek Philippe" }
            model: { regex: "/Calatrava/" }
            qty: { gt: 0 }
          }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Patek Philippe Calatrava Watches for Sale'}
      canonical={'/fine-watches/patek-philippe/calatrava/'}
      description={
        'Grays & Sons are experts in buying and selling second hand Patek Philippe Calatrava Watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/patek-philippe/filter/cat/calatrava/">
              <StaticImage
                src="../../images/preowned/preowned-patek-philippe-calatrava-banner.png"
                alt="Pre-Owned Certified Used Patek Philippe Calatrava Watches Header"
                aria-label="Used Patek Philippe Calatrava Image Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 lg:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">
              PATEK PHILIPPE CALATRAVA: PRE-OWNED, ESTATE, VINTAGE, ANTIQUE, HEIRLOOM, USED PATEK
              PHILIPPE WATCHES
            </h1>
            <h2>BUY, SELL, TRADE, AND REPAIR USED AND PRE OWNED PATEK PHILIPPE WATCHES</h2>
            <p>
              Gray and Sons Jewelers is considered the #1 Patek Philippe watch buyer and seller
              nationally known and located in Miami, Florida. Gray and Sons is known for our watch
              and jewelry experts including used Patek Philippe Calatrava watch experts who are
              on-site. The store is open six days a week, and we're a real, not a virtual or pop-up
              store, proudly assisting our customers for the past 42 years. We're sellers and buyers
              of luxury pre-owned, used Patek Philippe Calatrava watches servicing the entire USA,
              Miami, Miami Beach, Coral Gables, Coconut Grove, South Beach, Bal Harbour, Surfside,
              Sunny Isles , Aventura, Fort Lauderdale and many more areas. Online at{' '}
              <a href="/">www.grayandsons.com</a> or visit us in store at our watch and jewelry
              boutique showroom across from the Bal Harbour Shops.
              <br />
              <br />
              Buy your luxury Used Patek Philippe Watch with Gray and Sons. View our top inventory
              of used and pre-owned Patek Philippe watches. Every Patek Philippe wristwatch
              purchased at Gray and Sons is Certified pre-owned and comes backed with a 1-year Gray
              and Sons Jewelers Warranty. View our in-stock inventory of Patek Philippe Calatrava
              watches below. Viktoria, our live representative is ready to make a deal with you.
              Click here for a <a href="/chat-with-viktoria"> live chat.</a> She is a real decision
              maker. BUY, SELL, TRADE YOUR WATCH NOW.
              <br />
              <br />
              It is a frequently asked question, “Where can I sell my used watch for cash? ” And
              “Where do I sell my watch near me?”{' '}
              <a href="https://sellusyourjewelry.com">www.sellusyourjewelry.com </a>
              a watch buying department of Gray and Sons will buy your watch for cash. We pay
              highest prices for used watches in the shortest period of time. Gray and Sons is also
              the best place to trade watches and repair used and pre-owned Patek Philippe Calatrava
              watches.
              <br />
              <br />
              The Calatrava is Patek Philippe’s flagship dress watch, first introduced in 1932 as
              the Reference 96. That was the same year that the Stern family took over the ownership
              of Patek Philippe and many credit the Calatrava for helping the company survive the
              Great Depression. Thanks to their timeless elegance and exquisite craftsmanship, a
              Patek Philippe Calatrava, whether new or used, is one of the finest timepieces to own.
              <br />
              <br />
              At 90 years old, the Calatrava remains a mainstay of the Patek Philippe catalog. With
              its clean lines, perfectly symmetrical round case, straight lugs, and minimal dials,
              the Patek Philippe Calatrava is a classic dress watch. They are generally crafted from
              precious metals, such as gold or platinum, and typically come fitted with leather
              straps. While Patek has created some complications featuring Calatrava-style cases,
              the vast majority of Calatrava watches are either time-only or include a subtle date
              window on the dial.
              <br />
              <br />
              There are several benefits to buying vintage, estate, pre-owned and used Patek
              Philippe Calatrava watches, including saving money, sourcing a discontinued model, or
              finding a rare vintage example. If you’re shopping for a secondhand Patek Calatrava,
              here are a few things to keep in mind: 1. There are manual and automatic Calatrava
              models. 2. Calatrava watches can have flat, convex, or hobnail (a.k.a. Clous de Paris)
              bezels. 3. Dials can include a running seconds subdial, central seconds hand, or no
              seconds hand. 4. Depending on the reference, used Calatrava cases generally range from
              31mm to 39mm. 5. There are secondhand Calatrava watches with a hinged Officer style
              casebacks that can open.
              <br />
              <br />
              Popular Pre-Owned Patek Philippe Calatrava References are: Reference 96 Reference 565
              Reference 570 Reference 2406 Reference 2451 Reference 2449 Reference 2526 Reference
              2552 Reference 3403 Reference 3520 Reference 3744 Reference 3919 Reference 3960
              Reference 3992 Reference 4860 Reference 5022 Reference 5116 Reference 5119 Reference
              5153 Reference 5196 Reference 5227 Reference 5296 Reference 6006 Reference 6119
              <br />
              <br />
              Patek makes some of the finest watches in the market, complete with intricate
              mechanical movements. As such, you should only trust specialized watchmakers to work
              on your pre-owned Patek Philippe Calatrava. The Gray and Sons watch service facility
              is home to a team of skilled watchmakers who are highly knowledgeable in high-end
              Swiss timepieces. Gray and Sons master watchmakers are experts in servicing and
              repairing secondhand estate, vintage, antique, heirloom Patek Philippe watches. All of
              our certified pre-owned watches come freshly serviced, lubricated and timing adjusted.
              We offer Patek Philippe battery replacement on specific models. We offer certified
              pre-owned watches.
              <br />
              <br />
            </p>
            <div className="w-full flex justify-start">
              <a href="/fine-watches/patek-philippe/filter/cat/calatrava/">
                <button>SHOP PATEK PHILIPPE CALATRAVA WATCHES</button>
              </a>
            </div>
            <br />
            <br /> <br />
            <br />
          </div>
        </section>
      </div>

      <section>
        <h2 className="text-center py-10">IN-STOCK PATEK PHILIPPE WATCHES</h2>
        <ProductGrid products={data.products.nodes} />
      </section>

      <PatekCategoryBtns />

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/drvX30qLcgg'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default PatekAnnualCalendar
